import React, { useEffect } from 'react';

import { NotificationManager } from 'react-notifications';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import useData from '../../../common/useData';
import { TableEvents } from './TableEvents';

export function ModalEventCalendar({ open, setOpen, sede, ccaa }) {
	const { info } = useData();
	const [tipo, setTipo] = React.useState(1);
	const [title, setTitle] = React.useState('');
	const [fechaInicio, setFechaInicio] = React.useState(null);
	const [fechaFin, setFechaFin] = React.useState(null);

	const [list, setList] = React.useState([]);
	const [createEvent, setCreateEvent] = React.useState(false);

	useEffect(() => {
		setCreateEvent(false);

		fetch('https://server.fichajes.crossmedia360.com/getEventosDev', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				dni: info.dni,
				sede: sede
			})
		})
			.then((res) => res.json())
			.then((data) => {
				setList(data.eventos || []);
			})
			.catch((res) => {
				console.log('error');
			});
	}, [open, sede]);

	return (
		<div className='w-100 bg-grey scroll-y'>
			<Modal
				open={open}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className='scale-in bg-modal-incident d-flex align-items-center justify-content-center'
			>
				<Box className='scale-in modal-close-incident' sx={{ width: 800 }}>
					<CloseIcon className='closeModal' onClick={() => setOpen(false)} />
					<Typography
						id='modal-modal-title'
						className='d-flex justify-content-between'
						variant='h6'
						component='h2'
					>
						Eventos de {sede}{' '}
						<button
							className='btn btn-primary me-5'
							onClick={() => setCreateEvent(!createEvent)}
						>
							{!createEvent ? 'Crear evento' : 'Volver'}
						</button>
					</Typography>

					{!createEvent ? (
						<TableEvents events={list} />
					) : (
						<>
							<div className='mt-3 mb-3'>
								<div className='form-group'>
									<label htmlFor='tipo'>Tipo</label>
									<select
										defaultValue={tipo}
										className='form-control'
										id='tipo'
										onChange={(e) => setTipo(e.target.value)}
									>
										<option value='1'>Festivo</option>
										<option value='2'>Jornada reducida</option>
									</select>
								</div>

								{tipo == 1 ? (
									<div className='form-group mt-3 mb-3'>
										<label htmlFor='title'>Título</label>
										<input
											type='text'
											placeholder='Título del Evento'
											className='form-control'
											id='title'
											onChange={(e) => setTitle(e.target.value)}
										/>
									</div>
								) : null}

								<div className='form-group mt-3 mb-3'>
									<label htmlFor='fechaInicio'>Fecha de inicio</label>
									<input
										type='date'
										className='form-control'
										id='fechaInicio'
										onChange={(e) => setFechaInicio(e.target.value)}
									/>

									<label htmlFor='fechaFin' className='mt-3'>
										Fecha de fin*
									</label>
									<input
										type='date'
										className='form-control'
										id='fechaFin'
										onChange={(e) => setFechaFin(e.target.value)}
									/>
								</div>
							</div>

							<button
								className='btn btn-danger w-100 mt-2'
								onClick={() => {
									if (tipo && fechaInicio) {
										fetch('https://server.fichajes.crossmedia360.com/addEvento', {
											method: 'POST',
											headers: {
												'Content-Type': 'application/json'
											},
											body: JSON.stringify({
												type: tipo,
												title: title,
												fechaInicio: fechaInicio,
												fechaFin: fechaFin,
												sede: sede,
												ccaa: ccaa
											})
										})
											.then((res) => res.json())
											.then((data) => {
												if (data.added) {
													setOpen(false);
												}
											})
											.catch((res) => {
												console.log('error');
											});
									} else {
										NotificationManager.error(
											'Error al añadir el evento',
											'Error',
											5000
										);
									}
								}}
							>
								Crear
							</button>
						</>
					)}
				</Box>
			</Modal>
		</div>
	);
}
