import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, TextField } from '@mui/material';
import useData from '../../../common/useData';
import { NotificationManager } from 'react-notifications';

export const CopySchedule = ({ dni, show, setShow }) => {
	const { info } = useData();
	const [empleados, setEmpleados] = useState([]);
	const [selectedEmployees, setSelectedEmployees] = useState([]);

	const [disabledButton, setDisabledButton] = useState(false);

	useEffect(() => {
		fetch('https://server.fichajes.crossmedia360.com/getAllEmployeesDev', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ dni: info.dni })
		})
			.then((results) => {
				return results.json();
			})
			.then((data) => {
				let employees = [];

				data.employees.map((employee) => {
					employees.push({
						label: `${employee.dni} | ${employee.nombre} ${employee.apellidos}`,
						value: employee.dni
					});
				});

				setEmpleados(employees);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return (
		<div className='w-100 bg-grey scroll-y'>
			<Modal
				open={show}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className='scale-in bg-modal-incident d-flex align-items-center justify-content-center'
			>
				<Box className='scale-in modal-close-incident' sx={{ width: 800 }}>
					<CloseIcon className='closeModal' onClick={() => setShow(false)} />

					<div className='d-flex justify-content-around mb-2'>
						<Typography
							id='modal-modal-title'
							className='d-flex justify-content-between'
							variant='h6'
							component='h2'
						>
							Copiar Horarios
						</Typography>
					</div>

					<p>Selecciona los empleados a los que quieres copiarles el horario</p>

					<Autocomplete
						multiple
						id='selected-employees'
						options={empleados}
						getOptionLabel={(option) => option.label}
						renderInput={(params) => (
							<TextField
								{...params}
								variant='standard'
								label='Empleados'
								placeholder='Empleados'
							/>
						)}
						noOptionsText='No hay empleados'
						onChange={(event, newValue) => {
							setSelectedEmployees(newValue);
						}}
					/>

					<button
						className='btn btn-primary w-100 mt-3'
						disabled={disabledButton}
						onClick={() => {
							setDisabledButton(true);

							fetch('https://server.fichajes.crossmedia360.com/copySchedule', {
								method: 'POST',
								headers: {
									'Content-Type': 'application/json'
								},
								body: JSON.stringify({
									dni: dni,
									employees: selectedEmployees
								})
							})
								.then((results) => {
									return results.json();
								})
								.then((data) => {
									setDisabledButton(false);
									setShow(false);

									NotificationManager.success(
										'Horarios copiados correctamente',
										'Correcto',
										3000
									);
								})
								.catch((error) => {
									NotificationManager.error(
										'Error al copiar los horarios',
										'Error',
										3000
									);

									setDisabledButton(false);
								});
						}}
					>
						Copiar Horarios
					</button>
				</Box>
			</Modal>
		</div>
	);
};
