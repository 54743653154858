import React, { useEffect } from 'react';

import { NotificationManager } from 'react-notifications';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import useData from '../../../common/useData';
import { TableEvents } from './TableEvents';
import { CopySchedule } from './CopySchedule';

export function ModalHorario({ open, setOpen, userInfo }) {
	const [horarios, setHorarios] = React.useState([]);
	const [createHorario, setCreateHorario] = React.useState(false);

	const [jornadaPartida, setJornadaPartida] = React.useState(false);

	const [fechaInicio, setFechaInicio] = React.useState(null);
	const [fechaFin, setFechaFin] = React.useState(null);

	const [horaInicio, setHoraInicio] = React.useState(null);
	const [horaFin, setHoraFin] = React.useState(null);

	const [horaInicio2, setHoraInicio2] = React.useState(null);
	const [horaFin2, setHoraFin2] = React.useState(null);

	const [copySchedule, setCopySchedule] = React.useState(false);

	const [refresh, setRefresh] = React.useState(false);

	useEffect(() => {
		setCreateHorario(false);
	}, [open]);

	useEffect(() => {
		fetch('https://server.fichajes.crossmedia360.com/getHorarios', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				dni: userInfo.dni
			})
		})
			.then((res) => res.json())
			.then((data) => {
				if (!data.error) {
					setHorarios(data.horarios);
				}
			})
			.catch((res) => {
				console.log('error');
			});
	}, [open, userInfo, createHorario, refresh]);

	return open && copySchedule ? (
		<CopySchedule dni={userInfo.dni} show={copySchedule} setShow={setCopySchedule} />
	) : (
		open && (
			<div className='w-100 bg-grey scroll-y'>
				<Modal
					open={open}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
					className='scale-in bg-modal-incident d-flex align-items-center justify-content-center'
				>
					<Box className='scale-in modal-close-incident' sx={{ width: 800 }}>
						<CloseIcon
							className='closeModal'
							onClick={() => setOpen(false)}
						/>

						<div className='d-flex justify-content-around mb-4'>
							<Typography
								id='modal-modal-title'
								className='d-flex justify-content-between'
								variant='h6'
								component='h2'
							>
								HORARIOS DE {userInfo.nombre} {userInfo.apellidos}
							</Typography>

							{!createHorario && (
								<>
									<button
										className='btn'
										onClick={() => setCopySchedule(true)}
									>
										COPIAR HORARIOS
									</button>

									<button
										className='btn btn-primary'
										onClick={() => setCreateHorario(true)}
									>
										CREAR HORARIOS
									</button>
								</>
							)}

							{createHorario && !jornadaPartida ? (
								<button
									className='btn btn-primary'
									onClick={() => setJornadaPartida(true)}
								>
									Jornada partida
								</button>
							) : (
								createHorario && (
									<button
										className='btn btn-primary'
										onClick={() => {
											setHoraInicio2(null);
											setHoraFin2(null);

											setJornadaPartida(false);
										}}
									>
										Jornada continua
									</button>
								)
							)}
						</div>

						{!createHorario ? (
							<div style={{ maxHeight: '70vh', overflow: 'auto' }}>
								<table className='w-100'>
									<thead>
										<tr>
											<th className='pe-4'>Fecha Inicio</th>
											<th className='pe-4'>Fecha Fin</th>
											<th className='pe-4'>Horas (Mañana)</th>
											<th className='pe-4'>Horas (Tarde)</th>
											<th>Acciones</th>
										</tr>
									</thead>
									<tbody>
										{horarios.map((horario) => (
											<tr key={horario.id}>
												<td className='pe-4'>
													{new Date(
														horario.fechaInicio
													).toLocaleDateString()}
												</td>
												<td className='pe-4'>
													{new Date(
														horario.fechaFin
													).toLocaleDateString()}
												</td>
												<td className='pe-4'>
													{horario.horaMInicio} -{' '}
													{horario.horaMFin}
												</td>

												{horario.horaTInicio &&
													horario.horaTFin ? (
													<td className='pe-4'>
														{horario.horaTInicio} -{' '}
														{horario.horaTFin}
													</td>
												) : (
													<td style={{ fontStyle: 'italic' }}>
														Jornada continua
													</td>
												)}

												<td>
													<button
														className='btn btn-danger'
														onClick={() => {
															fetch(
																'https://server.fichajes.crossmedia360.com/deleteHorario',
																{
																	method: 'POST',
																	headers: {
																		'Content-Type':
																			'application/json'
																	},
																	body: JSON.stringify({
																		id: horario.id
																	})
																}
															)
																.then((res) => res.json())
																.then((res) => {
																	if (res.deleted) {
																		setRefresh(
																			(ref) => !ref
																		);
																	}
																})
																.catch((res) => {
																	console.log('error');
																});
														}}
													>
														ELIMINAR
													</button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						) : (
							<>
								<div className='mt-3 mb-3'>
									<div className='form-group row'>
										<div className='col-6'>
											<label htmlFor='fechaInicio'>
												Fecha de Inicio (Incluida)
											</label>
											<input
												type='date'
												className='form-control'
												id='fechaInicio'
												onChange={(e) =>
													setFechaInicio(e.target.value)
												}
											/>
										</div>

										<div className='col-6'>
											<label htmlFor='fechaFin'>
												Fecha de Fin (Incluida)
											</label>
											<input
												type='date'
												className='form-control'
												id='fechaFin'
												onChange={(e) =>
													setFechaFin(e.target.value)
												}
											/>
										</div>
									</div>

									<div className='form-group row mt-3'>
										<div className='col-6'>
											<label htmlFor='horaInicio'>
												Hora de Inicio
											</label>
											<input
												type='time'
												className='form-control'
												id='horaInicio'
												onChange={(e) =>
													setHoraInicio(e.target.value)
												}
											/>
										</div>

										<div className='col-6'>
											<label htmlFor='horaFin'>Hora de Fin</label>
											<input
												type='time'
												className='form-control'
												id='horaFin'
												onChange={(e) =>
													setHoraFin(e.target.value)
												}
											/>
										</div>
									</div>

									{jornadaPartida ? (
										<div className='form-group row mt-3'>
											<div className='col-6'>
												<label htmlFor='horaInicio'>
													Hora de Inicio (2ª jornada)
												</label>
												<input
													type='time'
													className='form-control'
													id='horaInicio'
													onChange={(e) =>
														setHoraInicio2(e.target.value)
													}
												/>
											</div>

											<div className='col-6'>
												<label htmlFor='horaFin'>
													Hora de Fin (2ª jornada)
												</label>
												<input
													type='time'
													className='form-control'
													id='horaFin'
													onChange={(e) =>
														setHoraFin2(e.target.value)
													}
												/>
											</div>
										</div>
									) : (
										<></>
									)}
								</div>

								<div className='d-flex'>
									<button
										className='btn btn-primary w-100 mt-2 me-4'
										onClick={() => setCreateHorario(false)}
									>
										VOLVER
									</button>
									<button
										className='btn btn-success w-100 mt-2'
										onClick={() => {
											if (
												fechaInicio &&
												fechaFin &&
												horaInicio &&
												horaFin
											) {
												fetch(
													'https://server.fichajes.crossmedia360.com/addHorario',
													{
														method: 'POST',
														headers: {
															'Content-Type':
																'application/json'
														},
														body: JSON.stringify({
															userId: userInfo.dni,
															fechaInicio: fechaInicio,
															fechaFin: fechaFin,
															horaInicio: horaInicio,
															horaFin: horaFin,
															horaInicio2:
																horaInicio2 || '-1',
															horaFin2: horaFin2 || '-1'
														})
													}
												)
													.then((res) => res.json())
													.then((data) => {
														if (data.added) {
															NotificationManager.success(
																'Horario añadido correctamente',
																'Horario añadido',
																5000
															);

															setOpen(false);
														}
													});
											} else {
												NotificationManager.error(
													'Error al añadir el horario',
													'Error',
													5000
												);
											}
										}}
									>
										Crear
									</button>
								</div>
							</>
						)}
					</Box>
				</Modal>
			</div>
		)
	);
}
