import React from 'react';
import useData from '../../../common/useData';
import { rfSoliciudes } from '../../../common/useRefresh';
import { TableSolicitudes } from '../components/TableSolicitudes';
import { BsFillPersonPlusFill } from 'react-icons/bs';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const Solicitudes = () => {
	const { info } = useData();
	const { solicitudesStatus } = rfSoliciudes();
	const [solicitudes, setSolicitudes] = React.useState([]);
	const [allSolicitudes, setAllSolicitudes] = React.useState([]);

	React.useEffect(() => {
		fetch('https://server.fichajes.crossmedia360.com/getAllRequestDev', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				dni: info.dni
			})
		})
			.then((results) => {
				return results.json();
			})
			.then((data) => {
				setSolicitudes(data.requests);
			})
			.catch((error) => {
				console.log(error);
			});

		fetch('https://server.fichajes.crossmedia360.com/getAllRequestStatus', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				dni: info.dni
			})
		})
			.then((results) => {
				return results.json();
			})
			.then((data) => {
				setAllSolicitudes(data.requests);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [solicitudesStatus]);

	return (
		<div className='w-100 bg-grey scroll-y p-2 pt-3'>
			<div className='container-fluid fade-in'>
				<div className='row'>
					<div className='col-6'>
						<h1>Solicitudes</h1>
					</div>

					<div className='col-6 d-flex justify-content-end'>
						<button
							type='button'
							className='btn btn-dark me-2'
							style={{ height: '40px' }}
							onClick={() => {
								const fileType =
									'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
								const fileExtension = '.xlsx';

								const ws = XLSX.utils.json_to_sheet(
									allSolicitudes.map((solicitud) => {
										solicitud.startDate = new Date(
											solicitud.startDate
										).toLocaleDateString();

										solicitud.endDate = new Date(
											solicitud.endDate
										).toLocaleDateString();

										solicitud.aproved =
											solicitud.aproved == 0
												? 'Pendiente'
												: solicitud.aproved == 1
													? 'Aprobado'
													: 'Rechazado';

										return solicitud;
									})
								);
								ws['!cols'] = [
									{},
									{ wch: 12 },
									{ wch: 15 },
									{ wch: 25 },
									{ wch: 25 },
									{ wch: 25 },
									{ wch: 20 },
									{ wch: 20 },
									{ wch: 50 }
								];
								const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
								const excelBuffer = XLSX.write(wb, {
									bookType: 'xlsx',
									type: 'array'
								});
								const data = new Blob([excelBuffer], { type: fileType });

								FileSaver.saveAs(data, 'solicitudes' + fileExtension);
							}}
						>
							<BsFillPersonPlusFill /> Exportar solicitudes
						</button>
					</div>
				</div>

				<div className='appendEmployeesDiv mt-3'>
					{solicitudes.length === 0 ? (
						<div className='alert alert-info' role='alert'>
							No hay solicitudes
						</div>
					) : (
						<TableSolicitudes data={solicitudes} />
					)}
				</div>
			</div>
		</div>
	);
};
