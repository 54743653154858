import React, { useState } from 'react';
import TemplateInformes from './components/TemplateInformes';

import { PDFDownloadLink } from '@react-pdf/renderer';
import useData from '../../common/useData';
import { useRol } from '../../common/useRol';
import MultiSelectCCAA from '../common/MultiSelectCCAA';

import dayjs from 'dayjs';
import BasicDateTimePicker from '../fichajes/components/SelectDateRange';
import TemplateInformes2 from './components/TemplateInformes2';
import TemplateInformeRequest from './components/TemplateInformeRequest';

export default function Informes() {
	const { info } = useData();

	const [permisos, setPermisos] = useState([]);
	const [employees, setEmployees] = useState([]);
	const [fichajesErroneos, setFichajesErroneos] = useState([]);
	const [downloadReady, setDownloadReady] = useState(false);
	const [downloadReady2, setDownloadReady2] = useState(false);
	const [tipoInforme, setTipoInforme] = useState('fichajes');
	const [tipoPermiso, setTipoPermiso] = useState('');

	const [data, setData] = useState({
		ccaa: '',
		sede: ''
	});

	const [filterDataInicio, setFilterDataInicio] = useState('');
	const [filterDataFin, setFilterDataFin] = useState(dayjs(new Date()));

	const errorFichajes = async () => {
		setDownloadReady2(false);
		setFichajesErroneos([]);

		if (data?.ccaa != '') {
			await fetch('https://server.fichajes.crossmedia360.com/getErrorFichajes', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					dni: info.dni,
					data: data
				})
			})
				.then((results) => results.json())
				.then((res) => {
					let ccaaValues = data?.ccaa.map((ccaa) => ccaa.value);
					let sedeValues = data?.sede && data?.sede.map((sede) => sede.value);

					let fichajes = [];

					res.fichajes.forEach((fichaje) => {
						if (
							ccaaValues.includes('all') ||
							ccaaValues.includes(fichaje.ccaa)
						) {
							if (sedeValues && sedeValues.length > 0) {
								if (
									sedeValues.includes('all') ||
									sedeValues.includes(fichaje.sede)
								) {
									fichajes.push(fichaje);
								}
							} else {
								fichajes.push(fichaje);
							}
						}
					});

					setFichajesErroneos(fichajes);
					setDownloadReady2(true);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	async function takeData() {
		setDownloadReady(false);

		if (data?.ccaa !== '' || (filterDataInicio !== '' && filterDataFin !== '')) {
			await fetch('https://server.fichajes.crossmedia360.com/reportFichajesDev', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					dni: info.dni,
					data: data,
					filterDataInicio: filterDataInicio,
					filterDataFin: filterDataFin
				})
			})
				.then((results) => {
					return results.json();
				})
				.then((data) => {
					let tempFichajes = [];

					for (const i in data?.fichajes) {
						if (Object.hasOwnProperty.call(data?.fichajes, i)) {
							const element = data?.fichajes[i];
							tempFichajes.push(element);
						}
					}

					setEmployees(tempFichajes);
				})
				.catch((error) => {
					console.log(error);
				});
			setDownloadReady(true);
		}
	}

	async function takePermisos() {
		let ccaaValues = data?.ccaa && data?.ccaa?.map((ccaa) => ccaa.value) || [];
		let sedeValues = data?.sede && data?.sede?.map((sede) => sede.value) || [];

		if (ccaaValues.includes('all')) {
			ccaaValues = [];
		}

		if (sedeValues.includes('all')) {
			sedeValues = [];
		}

		await fetch('https://server.fichajes.crossmedia360.com/reportPermisos', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				tipoPermiso, ccaaValues, sedeValues
			})
		})
			.then((results) => {
				return results.json();
			})
			.then((data) => {
				if (!data.error) setPermisos(data.permisos);

			})
			.catch((error) => {
				console.log(error);
			});

		setDownloadReady(true);
	}


	return (
		<div className='d-flex align-items-center justify-content-center w-100 h-100 scale-in'>
			<div className='card' style={{ minWidth: '500px' }}>
				<div className='card-body'>
					<h4>Generar Informe</h4>

					<div className='d-flex gap-4'>
						<select
							className='form-select w-50'
							placeholder='Tipo de informe'
							value={tipoInforme}
							onChange={(e) => setTipoInforme(e.target.value)}
						>
							<option value='fichajes'>Fichajes</option>
							<option value='errorFichajes'>Fichajes erroneos</option>
							<option value='permisos'>Permisos</option>
						</select>

						{tipoInforme === 'permisos' ? (
							<select
								className='form-select w-50'
								placeholder='Tipo de informe'
								onChange={(e) => setTipoPermiso(e.target.value)}
							>
								<option value=''>Tipo de permiso</option>
								<option value='vacaciones'>Vacaciones</option>
								<option value='diasPropios'>Días Propios</option>
								<option value='otrosMotivos'>Otros</option>
								<option value=''>Todos</option>
							</select>
						) : null}
					</div>

					<MultiSelectCCAA
						data={data}
						dataType='filtro'
						changeFunction={setData}
					/>

					{tipoInforme === 'fichajes' ? (
						<>
							<BasicDateTimePicker
								type='inicio'
								changeFunction={setFilterDataInicio}
							/>
							<BasicDateTimePicker
								type='fin'
								changeFunction={setFilterDataFin}
							/>
						</>
					) : null}

					<div className='w-100'>
						<button
							className='btn btn-primary w-100 mt-2'
							onClick={() => {
								if (tipoInforme === 'fichajes') takeData();
								else if (tipoInforme === 'errorFichajes') errorFichajes();
								else if (tipoInforme === 'permisos') takePermisos();
							}}
						>
							Consulta
						</button>
					</div>

					{downloadReady ? (
						<>
							{/* <PDFDownloadLink document={<TemplateInformes info={employees} title="Trabajadores" />} fileName="listado_trabajadores.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading document...' : 'Descargar informe'
                            }
                        </PDFDownloadLink> */}

							<PDFDownloadLink
								document={
									<TemplateInformeRequest
										info={permisos}
										title={'Trabajadores '}
									/>
								}
								fileName='listado_trabajadores.pdf'
								style={{
									display: 'block',
									padding: '20px 0',
									textTransform: 'uppercase'
								}}
								className='btn btn-secondary mt-2'
								onClick={(event) => {
									event.preventDefault();
									window.open(event.target.href, '_blank');
									setDownloadReady(false);
								}}
							>
								{({ blob, url, loading, error }) =>
									loading ? 'Cargando informe...' : 'Abrir informe'
								}
							</PDFDownloadLink>
						</>
					) : null}

					{downloadReady2 ? (
						<>
							{/* <PDFDownloadLink document={<TemplateInformes info={employees} title="Trabajadores" />} fileName="listado_trabajadores.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading document...' : 'Descargar informe'
                            }
                        </PDFDownloadLink> */}

							<PDFDownloadLink
								document={
									<TemplateInformes2
										info={fichajesErroneos}
										title={'Fichajes erroneos'}
									/>
								}
								fileName='listado_trabajadores.pdf'
								style={{
									display: 'block',
									padding: '20px 0',
									textTransform: 'uppercase'
								}}
								className='btn btn-secondary mt-2'
								onClick={(event) => {
									event.preventDefault();
									window.open(event.target.href, '_blank');
									setDownloadReady2(false);
								}}
							>
								{({ blob, url, loading, error }) =>
									loading ? 'Cargando informe...' : 'Abrir informe'
								}
							</PDFDownloadLink>
						</>
					) : null}
				</div>
			</div>

			{/* <PDFViewer style={{width: "100%", height:"100vh"}}>
                <TemplateInformes data={employees} title={"Trabajadores Crossmedia"} />
            </PDFViewer> */}
		</div>
	);
}
