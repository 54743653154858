import React, { useState, useEffect } from 'react'
import { Autocomplete, TextField } from '@mui/material';

import useData from '../../common/useData'

export default function SelectCCAA({ data, defaultValue, dataType, changeFunction }) {
    const [ccaa, setCCAA] = useState([]);

    const { info } = useData();

    useEffect(() => {
        fetch("https://server.fichajes.crossmedia360.com/getAllCCAA", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ dni: info.dni }),
        }).then(results => {
            return results.json();
        })
            .then(data => {
                let options = [];
                data.ccaa.map((ccaa, index) => {
                    options.push({ label: ccaa.nombre, value: ccaa.nombre });
                })
                setCCAA(options);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])

    return (
        <>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={ccaa}
                className="pt-2 w-100"
                value={data ? data.ccaa : null}
                renderInput={(params) => <TextField {...params} label="CCAA" />}
                onChange={(e, newValue) => data != "none" ? dataType == 'ccaa' ? changeFunction({ ...data, ccaa: newValue.value }) : changeFunction(newValue.value) : changeFunction(newValue.value)}
            />
        </>

    )
}
