import './App.css';

import React, { useState } from 'react';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import useToken from './common/useToken';
import useData from './common/useData';
import { useRol } from './common/useRol'

import Login from './modules/login/Login';
import Main from './modules/main/Main';

function App() {
    const { token, setToken } = useToken();
    const { setInfo } = useData()
    const { setRol } = useRol()

    const [show, setShow] = useState(false);

    React.useEffect(async () => {
        const loggedUserToken = window.localStorage.getItem('token');
        const loggedUserInfo = window.localStorage.getItem('info');
        const loggedUserRol = window.localStorage.getItem('rol');

        if (loggedUserToken && loggedUserInfo && loggedUserRol) {
            const Token = JSON.parse(loggedUserToken);
            const user = JSON.parse(loggedUserInfo);
            const rol = loggedUserRol;

            const response = await fetch('https://server.fichajes.crossmedia360.com/authToken', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ token: Token })
            })
            const data = await response.json()

            if (data.verify) {
                setToken(Token);
                setInfo(user);
                setRol(rol);
                window.history.pushState({}, null, localStorage.getItem('url') || "/fichajes/lista")
            }
        }
    }, []);

    if (!token) {
        return (
            <Login />
        )
    }

    return (
        <>
            {token ?
                <>
                    <Main />
                </>
                :
                <Login />
            }

            <NotificationContainer />
        </>
    );
}

export default App;
