import React, { useState, useEffect } from 'react'
import { BsSearch, BsFillPersonPlusFill } from 'react-icons/bs';

import useData from '../../../common/useData';

import { EnhancedTableSedes } from '../components/TableSedes';
import CreateSede from './CreateSede';

import { rfSedes } from '../../../common/useRefresh';
import { soCreateSede } from '../../../common/useOpen';

export default function Sede() {
    const { info } = useData();
    const [sedes, setSedes] = useState([]);
    const [searchValSedes, setSearchSedes] = useState('');

    const { sedesStatus } = rfSedes();
    const { setCreateSede } = soCreateSede();

    useEffect(() => {
        fetch("https://server.fichajes.crossmedia360.com/getAllSedesDev", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                dni: info.dni
            }),
        }).then(results => {
            return results.json();
        })
            .then(data => {
                console.log(data)
                setSedes(data.sedes);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [sedesStatus])

    return (
        <div className="w-100 bg-grey scroll-y p-2 pt-3">
            <div className="container-fluid fade-in">
                <div className="row">
                    <div className="col-sm-6">
                        <h1>Sedes</h1>
                    </div>

                    <div className="col-sm-6 d-flex justify-content-end pt-2 pb-2">
                        <button type="button" className="btn btn-primary" onClick={() => {
                            setCreateSede(true, null)
                        }}><BsFillPersonPlusFill /> Crear</button>
                    </div>
                </div>

                <div className='appendEmployeesDiv mt-3'>
                    <CreateSede />

                    {sedes.length > 0 && sedes ?
                        <EnhancedTableSedes data={sedes} />
                        :
                        <EnhancedTableSedes data={[]} />
                    }
                </div>
            </div>
        </div>
    )
}
