import React, { useState } from "react";
import Papa from "papaparse";
import { NotificationManager } from "react-notifications";
import { rfEmployees } from "./useRefresh";

export const LeerCSV = ({ setOpen }) => {
    const { employeeStatus, setEmployeeStatus } = rfEmployees();
    const [parsedData, setParsedData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [values, setValues] = useState([]);

    const changeHandler = (event) => {
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,

            complete: function (results) {
                const rowsArray = [];
                const valuesArray = [];

                results.data.map((d) => {
                    rowsArray.push(Object.keys(d));
                    valuesArray.push(Object.values(d));
                });

                setParsedData(results.data);

                setTableRows(rowsArray[0]);

                setValues(valuesArray);
            },
        });
    };

    return (
        <>
            <div className="row text-center">
                <div className="col-6">
                    <input
                        type="file"
                        name="file"
                        onChange={changeHandler}
                        accept=".csv"
                        style={{ display: "block", margin: "10px auto" }}
                    />
                </div>

                <div className="col-6">
                    <button className="btn btn-primary" onClick={() => {
                        fetch("https://server.fichajes.crossmedia360.com/createEmployeesByCSV", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({ data: parsedData }),
                        }).then(results => { return results.json(); }).then(data => {
                            if (data.complete) {
                                NotificationManager.success("Empleados creados correctamente", "Éxito", 5000);
                                setEmployeeStatus(!employeeStatus);
                                setOpen(false);
                            } else {
                                NotificationManager.error("Error al crear los empleados", "Error", 5000);
                            }
                        }).catch((error) => {
                            NotificationManager.error("Error al crear los empleados", "Error", 5000);
                        });
                    }}>Crear Empleados</button>
                </div>
            </div>

            <br />

            {values && values.length > 0 ? <h5 className="text-center">Se van a crear los siguientes empleados:</h5> : null}
            <table className="text-center w-100">
                <thead>
                    <tr>
                        {tableRows.map((rows, index) => {
                            if (index <= 10)
                                return <th key={index}>{rows.toUpperCase()}</th>;
                        })}
                    </tr>
                </thead>

                <tbody>
                    {values.map((value, index) => {
                        return (
                            <tr key={index}>
                                {value.map((val, i) => {
                                    if (i <= 10)
                                        return <td key={i}>{val}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    )
}