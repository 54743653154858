import { Autocomplete, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react'

import useData from '../../../common/useData'

export default function SelectEmployee({ data, dataType, changeFunction }) {
    const { info } = useData();
    const [employees, setEmployees] = useState([]);

    useEffect(() => {
        fetch('https://server.fichajes.crossmedia360.com/getAllEmployeesDev', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                dni: info.dni
            })
        }).then(results => {
            return results.json();
        }
        ).then(res => {
            let options = [];
            res.employees.map((employee, index) => {
                options.push({
                    label: employee.dni + " | " + employee.nombre + " " + employee.apellidos,
                    value: employee.dni
                });
            })
            setEmployees(options);
        }
        ).catch((error) => {
            console.log(error);
        });
    }, [])

    return (
        <>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={employees}
                renderInput={(params) => <TextField {...params} label="Empleado" />}
                value={data.dni}
                onChange={(e, newValue) => data != "none" ? dataType == 'dni' ? changeFunction({ ...data, dni: newValue.value }) : changeFunction(newValue.value) : changeFunction(newValue.value)}
            />
        </>
    )
}
